import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostLayout from "../layouts/post-layout.tsx";
import { Link } from "gatsby";
export const _frontmatter = {
  "title": "Tech stack",
  "path": "/stack"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PostSeoComponent = makeShortcode("PostSeoComponent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PostSeoComponent title='Tech stack' path='/stack' mdxType="PostSeoComponent" />
    <h2>{`Tech stack`}</h2>
    <p>{`This project is built with open-source software, made by awesome people from all around the world:`}</p>
    <h3>{`CI/CD`}</h3>
    <ul>
      <li parentName="ul">{`GitLab`}</li>
      <li parentName="ul">{`GitLab pipelines`}</li>
      <li parentName="ul">{`Docker`}</li>
      <li parentName="ul">{`NGINX`}</li>
      <li parentName="ul">{`Traefik`}</li>
      <li parentName="ul">{`Let's Encrypt`}</li>
    </ul>
    <h3>{`Libraries/Frameworks`}</h3>
    <ul>
      <li parentName="ul">{`Node`}</li>
      <li parentName="ul">{`TypeScript`}</li>
      <li parentName="ul">{`React`}</li>
      <li parentName="ul">{`Gatsby`}</li>
      <li parentName="ul">{`SCSS`}</li>
      <li parentName="ul">{`MDX`}</li>
      <li parentName="ul">{`Prism `}</li>
    </ul>
    <h3>{`Testing/QA`}</h3>
    <ul>
      <li parentName="ul">{`ESLint`}</li>
    </ul>
    <p>{`Theme: by me`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      